export const STATUS_OPTION = [
  { label: "Default", value: "Default" },
  { label: "Dead", value: "Dead" },
  { label: "Alive", value: "Alive" },
  { label: "Unknown", value: "Unknown" },
];

export const GENDER_OPTION = [
  { label: "Default", value: "Default" },
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  { label: "Unknown", value: "Unknown" },
];
